import { action, flow, makeObservable, observable } from 'mobx';
import api from '../utils/apiService';

export default class Report {
  constructor() {
    this.data = [];

    makeObservable(this, {
      data: observable,
      fetch: flow,
      fetchByDate: flow,
      fetchByProperty: flow,
      fetchByDateProperty: flow,
      setData: action,
    });
  }

  setData() {
    this.data = [];
  }

  *fetch() {
    try {
      const response = yield api.get(`/report`);
      this.data = response.data;
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status, error: error?.response?.data };
    }
  }

  *fetchByProperty(propertyId) {
    try {
      const response = yield api.get(`/report/filterByDate/${propertyId}`);
      this.data = response.data;
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status, error: error?.response?.data };
    }
  }

  *fetchByDate(startDate, endDate) {
    try {
      const response = yield api.get(`/report/filterByDate?startDate=${startDate}&endDate=${endDate}`);
      this.data = response.data;
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status, error: error?.response?.data };
    }
  }

  *fetchByDateProperty(startDate, endDate, propertyId) {
    try {
      const response = yield api.get(`/report/filterByDateProperty?startDate=${startDate}&endDate=${endDate}&propertyId=${propertyId}`);
      this.data = response.data;
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status, error: error?.response?.data };
    }
  }
}