import { useCallback, useContext, useMemo } from 'react';

import config from '../../config';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '../../store';
import ToggleMenu from '../ToggleMenu';
import { LogoNavBlackIllustration, LogoNavIllustration } from '../Illustrations';
import { Typography, Box } from '@material-ui/core';
import { UserCircle, UserCircle2 } from 'lucide-react';

const OrganizationSwitcher = observer((index) => {
  const store = useContext(StoreContext);
  const handleChange = useCallback(
    ([{ id }]) => {
      if (store.organization.selected?._id !== id) {
        const organization = store.organization.items.find(
          ({ _id }) => _id === id
        );
        window.location.assign(
          `${config.BASE_PATH}/${organization.locale}/${organization.name}/dashboard`
        );
      }
    },
    [store.organization.selected, store.organization.items]
  );

  const options = useMemo(
    () =>
      store.organization.items.map(({ _id, name }) => ({
        id: _id,
        label: name,
      })),
    [store.organization.items]
  );

  const value = useMemo(
    () => options.find(({ id }) => id === store.organization?.selected?._id)?.id,
    [options, store.organization.selected]
  );
  console.log(store.organization)
  return store.organization?.items?.length > 0 ? (
    // <ToggleMenu
    //   startIcon={index.index === 1 ? <LogoNavBlackIllustration height={37} width={37} /> : <LogoNavIllustration height={47} width={47} />}
    //   options={options}
    //   selectedIds={value ? [value] : []}
    //   onChange={handleChange}
    // />
    <Box display="flex">
      {/* <UserCircle2/> */}
      <Typography style={{paddingLeft: "15px"}}>Halo, {store.organization.selected.name}</Typography>
    </Box>
  ) : null;
});

export default OrganizationSwitcher;
