import { env } from '@microrealestate/commonui/utils';

const config = {
  APP_NAME: env('APP_NAME'),
  BASE_PATH: '', //, env('BASE_PATH') || '',
  CORS_ENABLED: env('CORS_ENABLED') === 'true',
  DEMO_MODE: env('DEMO_MODE') === 'true',
  DOCKER_GATEWAY_URL: env('DOCKER_GATEWAY_URL'),
  GATEWAY_URL: env('GATEWAY_URL'),
  LANDING_PAGE_URL: env('LANDING_PAGE_URL'),
  LANDING_PAGE_BUCKET: env('LANDING_PAGE_BUCKET'),
  NODE_ENV: env('NODE_ENV'),
  SIGNUP: env('SIGNUP') === 'true',
  AUTHENTICATOR_URL: env('AUTHENTICATOR_URL'),
  ACCESS_TOKEN_KEY:"9ba877d313bd415330d52f32ab2dc2c2",
  ORG_KEY:"9ca877d313bd415330d52f32ab2dc3F6"  
};

export default config;
